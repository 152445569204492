import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Client from "../scenes/clients/Client";
import ClientView from "../scenes/clients/ClientView";
import Dashboard from "../scenes/dashboard";
import AllLeads from "../scenes/dashboard/AllLeads";
import CompanyDashLead from "../scenes/dashboard/CompanyDashLead";
import Leads from "../scenes/forms";
import CreateLead from "../scenes/forms/CreateLead";
import InitialForm from "../scenes/forms/leadcreate";
import LeadView from "../scenes/forms/LeadView";
import Opportunity from "../scenes/forms/Opportunity";
import RetailOpportunity from "../scenes/forms/RetailOpportunity";
import RPoppertunity from "../scenes/forms/RPoppertunity";
import UpdateNRPOpportunity from "../scenes/forms/UpdateNRPOpportunity";
import UpdateRetailOpportunity from "../scenes/forms/UpdateRetailOpportunity";
import UpdateRPOpportunity from "../scenes/forms/UpdateRPOpportunity";
import CreatePermission from "../scenes/permission/CreatePermission";
import RolePermission from "../scenes/permission/RolePermission";
import UserRolePermission from "../scenes/permission/UserRolePermission";
import CreateRole from "../scenes/roles/CreateRole";
import Roles from "../scenes/roles/Roles";
import RoleUpdate from "../scenes/roles/RoleUpdate";
import SolutionDevelopment from "../scenes/solutiondevelop/SolutionDevelopment";
import User from "../scenes/users";
import EditLead from "../scenes/forms/EditLead";
import OpportunityView from "../scenes/solutiondevelop/OpportunityView";
import MailConfigList from "../scenes/mailconfig/MailConfigList";
import Monitor from "../scenes/monitor/Monitor";
import SolutionGallery from "../scenes/solutiongallery/SolutionGallery";
import Thankyou from "../scenes/solutiongallery/Thankyou";
import BackendLead from "../scenes/solutiongallery/BackendLead";
import BackendLeadView from "../scenes/solutiongallery/BackendLeadView";
import BackendRetailView from "../scenes/solutiongallery/BackendRetailView";


const NavPage = () => {
  
  const location = useLocation();

  return (
    <React.Fragment>
      <section>
        <Routes>
            <Route path="/" element={<Dashboard />} />   

            {/* Config */}
            <Route path="/user_permission" element={<CreatePermission />} /> 
            <Route path="/role_permission" element={<RolePermission />} /> 
            <Route path="/user_role_permission" element={<UserRolePermission />} /> 

            {/* Master */}
            <Route path="/users" element={<User />} />  
            <Route path="/clients" element={<Client />} /> 
            <Route path="/client_view/:id" element={<ClientView />} /> 
            <Route path="/roles" element={<Roles />} /> 
            <Route path="/role_update/:id" element={<RoleUpdate />} /> 
            <Route path="/role_create" element={<CreateRole />} /> 
            <Route path="/mail_config" element={<MailConfigList />} /> 

            {/* Lead Trnx */}
            <Route path="/leads_create" element={<InitialForm />} />     
            <Route path="/leads_edit/:id" element={<EditLead />} />      
            <Route path="/lead_view/:id" element={<LeadView />} />  
            <Route path="/leads" element={<Leads />} /> 
            <Route path="/lead_history" element={<CompanyDashLead />} /> 
            <Route path="/all_leads" element={<AllLeads />} />   
            <Route path="/create_leads" element={<CreateLead />} />  
            <Route path="/create_opportunity/:id" element={<Opportunity />} />
            <Route path="/create_rpopportunity/:id" element={<RPoppertunity />} />
            <Route path="/create_reopportunity/:id" element={<RetailOpportunity />} />      
            <Route path="/opportunity_view/:id" element={<SolutionDevelopment />} />  
            <Route path="/update_opportunity/:id" element={<UpdateNRPOpportunity />} />      
            <Route path="/update_rpopportunity/:id" element={<UpdateRPOpportunity />} />    
            <Route path="/update_reopportunity/:id" element={<UpdateRetailOpportunity />} />   
            <Route path="/solution_gallery" element={<SolutionGallery />} />
            <Route path="/solution_development/:id" element={<OpportunityView />} />
            <Route path="/thank_you" element={<Thankyou />} />
            <Route path="/backend_leads" element={<BackendLead />} />
            <Route path="/backend_leadview/:id" element={<BackendLeadView />} />
            <Route path="/backend_retailview/:id" element={<BackendRetailView />} />

            {/* Lead sent to holisight */}
            <Route path="/monitor" element={<Monitor />} />

        </Routes>
      </section>
    </React.Fragment>
  );
};

export default NavPage;
